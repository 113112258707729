/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import whyDidYouRender from '@welldone-software/why-did-you-render'

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  whyDidYouRender(React)
}

import { LicenseManager } from 'ag-grid-enterprise'
import 'react-dates/initialize'
import { ApolloProvider } from '@apollo/client'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import * as am4core from '@amcharts/amcharts4/core'

// setup font awesome for next
import { config, library } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

// setup font awesome library
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import {
  faCalendarExclamation,
  faChevronCircleUp,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowLeft,
  faArrowRight,
  faAt,
  faAward,
  faBinoculars,
  faCalendar,
  faCheckSquare,
  faChevronCircleDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCommentAltPlus,
  faEdit,
  faFileAlt,
  faFilePdf,
  faFolders,
  faHandshake,
  faHardHat,
  faImage,
  faPhoneAlt,
  faPlusCircle,
  faSave,
  faTachometerAltFastest,
  faTimesCircle,
  faTrashAlt,
  faUsdCircle,
  faUserHardHat,
  faTimes,
  faCloudUpload,
  faPlay,
  faStar,
  faSearch,
  faThumbtack,
  faUserCircle,
  faAngleDown,
  faAngleUp,
  faEllipsisH,
  faHorizontalRule,
} from '@fortawesome/pro-light-svg-icons'
import { faCircle, faSpinner } from '@fortawesome/pro-duotone-svg-icons'

library.add(
  far,
  fas,
  faAngleLeft,
  faAngleRight,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowLeft,
  faArrowRight,
  faAt,
  faAward,
  faBinoculars,
  faCalendar,
  faCalendarExclamation,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClock,
  faCommentAltPlus,
  faEdit,
  faFileAlt,
  faFilePdf,
  faFolders,
  faHandshake,
  faHardHat,
  faImage,
  faImage,
  faPhoneAlt,
  faPlusCircle,
  faSave,
  faSpinner,
  faTachometerAltFastest,
  faTimesCircle,
  faTrashAlt,
  faUsdCircle,
  faUserHardHat,
  faUserCircle,
  faTimes,
  faCloudUpload,
  faPlay,
  faStar,
  faSearch,
  faThumbtack,
  faAngleDown,
  faAngleUp,
  faEllipsisH,
  faHorizontalRule
)

// utils
import { IdentityProvider } from '@/utils/passport/withIdentity'
import { CompanyProvider } from '@/utils/context/CompanyContext'
import { GridProvider } from '@/utils/context/grid'
import withApollo from '@/graphql/client'
import '../scss/main.scss'
import { init } from '@/utils/analytics/sentry'

init()

LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY)

am4core.addLicense(process.env.NEXT_PUBLIC_AM_CHARTS_KEY_1)
am4core.addLicense(process.env.NEXT_PUBLIC_AM_CHARTS_KEY_2)

const App = ({ Component, pageProps, apollo, err }) => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', (url) => {
      NProgress.start()
    })
    router.events.on('routeChangeComplete', () => NProgress.done())
    router.events.on('routeChangeError', () => NProgress.done())

    return () => {
      router.events.off('routeChangeStart', (url) => {
        NProgress.start()
      })
      router.events.off('routeChangeComplete', () => NProgress.done())
      router.events.off('routeChangeError', () => NProgress.done())
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // add Userback to global scope
      global.Userback = window.Userback || {}
      global.Userback.access_token =
        '9720|18862|bV9tp0AiEO0gClL9kbaVl6pKD7lEqscvcg2jyNkCfAivtVFlvG'
    }
  })

  return (
    <>
      <Head>
        <title>OPEX</title>
        <script async src="https://static.userback.io/widget/v1.js" />
      </Head>
      <CompanyProvider>
        <IdentityProvider>
          <ApolloProvider client={apollo}>
            <SnackbarProvider>
              <GridProvider>
                <Component {...pageProps} err={err} />
              </GridProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </IdentityProvider>
      </CompanyProvider>
    </>
  )
}

export default withApollo(App)
